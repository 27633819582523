@import "../../../assets/styles/content";

@font-face {
    font-family: Authentic;
    src: url(fonts/authentic.otf);
}

.content-retrato {
    margin-top: 90px;
    height: calc(100vh - 90px);
    width: 100vw;
    margin-bottom: 50px;
}

.product-container {
    font-size: 12px;
    background: #f8f8f8;
    padding-bottom: 50px;
}

.row {
    margin-top: 2em;
    margin-bottom: 2em;
}

.product {
    img {
        border-radius: 5px;
        padding: 0px;
        width: 100%;
    }

    .description {
        font-size: 12px;
        
        h1 { font-size: 20px; font-weight: 800; }
        
        p { font-family: Arial, Helvetica, sans-serif; font-size: 15px; padding: 0px; }
        
        .breadcrumb {
            font-size: 12px;
            color: #fff;
            text-align: right;
            font-weight: 600;
            background-color: #8c937b;
            font-family: Arial, Helvetica, sans-serif;
        }

        .justify-right {
            justify-content: flex-end;
        }
    }
}

.text-left { align-items: flex-start; }
.align-top { align-items: flex-start; }

.filters {
    padding:15px;
    text-align: center;
    .col {
        display: flex;
        justify-content: center;
        > div.button {
            font-family: ATypewriterForMe;
            background-color: white;
            border: none;
            padding: 0 10px;
            margin: 5px;
            color: black;
            font-size: 15px;
            cursor: pointer;

            &.selected { border-bottom: 1px solid #000; }
            
            &:hover { border-bottom: 1px solid #000; }
        }
    }
}

.courses {
    display: none;
    transition: display 1s linear;

    &.show {
        display: flex !important;
        transition: display 1s linear;
    }
    .col {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 25px;

        > img { border-radius: 3px;}

        .item-badge { display: inline; margin: 5px}
        
        @media (max-width: 374px) {
            flex-direction: column;
        }
    }

    > .col:hover {
        box-shadow: 0px 0px 8px 1px #e0e0e0;
        border-radius: 5px;
    }

    .item-detail { padding: 20px; }
    .item-photos img { max-width: 50px; display: inline; padding: 1px;}
    .item-photos-mobile { display: none;}
    .item-photos img:hover {
        box-shadow: 0px 0px 5px #000;
    }
    .col span { display: block;}
    .item-title { font-weight: 500; }
    .item-price { font-style: italic; text-align: right;}
    button { float: right; margin: 10px; }
}

.paypal-form {
    form {
        table {
            margin: 0 auto;
        }
        table+input {
            margin-top: 15px;
        }
    }
}

.modal-50w {
    max-width: 50vw;
}

span.item-title {
    white-space: pre-line;
}

.background {
    height: calc(100vh - 90px);
    width: 100vw;
    overflow: scroll;
    position: static;
}

.fete-content {
    font-family: Authentic;
    color: white;
    height: 100%;
    text-align: center;
    overflow: scroll;
}

.first {
    display: flex;
    height: calc(100vh - 90px);
    flex-direction: column;
    justify-content: center;

    h1 { font-size: 9rem; }
    h2 { font-size: 3rem; }
    p { font-size: 5rem; }
}

.second {
    text-align: center;
    display: flex;
    flex-direction: row;
    margin: 50px;

    img {
        width: auto;
        height: 500px;
        padding: 10px;
    }
}

.third {
    height: calc(100vh - 125px);
    .options {
        display: flex;
        flex-direction: row;
        justify-content: center;

        button {
            background: none;
            border: 1px solid white;
            margin: 15px;
            padding: 5px;
            color: white;
            border-radius: 5px;
            font-size: 35px;
        }
    }
}

// .description {
//     font-family: ATypewriterForMe;
//     padding: 15px;

//     > h1 {
//         font-size: 20px;
//     }
//     > p {
//         font-size: 15px;
//     }
// }

.background-retrato {
    opacity: 1;
    background: url("images/bg.jpg") 50% no-repeat;
    background-size: cover;
}

footer { display: flex !important; margin-top: 0px !important; }

.topics {
    font-size: 23px;
    text-align: left;
    font-family: 'ATypewriterForMe';

    ul, li { display: block;}
}

.dates {
    font-size: 40px;
}

.division {
    font-weight: 800;
    font-style: italic;
    text-align: center;
}

.white { color: #f3f3f3;}
.dark-white { color: #a02224;}
.align-self-center { align-self: center;}
.justify-center { justify-content: center;}
.flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }
.max-w-600 { max-width: 500px;}
.p-20 { padding: 20px; }

@media only screen and (min-device-width: 320px) and (max-device-width: 960px) {
    .content-retrato { margin-top: 80px; height: calc(100vh - 80px); }
    .background-retrato { height: calc(100vh - 80px); }
    .modal { padding-top: 90px; }
    .second {
        margin: 15px;
        display: block;
        img { height: 250px; } 
    }
    .first { 
        h1 { font-size: 4rem;}
        h2 { font-size: 2.5rem; }
        p { font-size: 3rem;}
    }

    .demoImg img {
        width: 300px;
    }
    .third { display: block;}
    .flex { display: block;}
}
