.image-slider {
    height: 100vh;
    overflow: hidden;
    .slide {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: absolute;
        transition: opacity 1s ease-in-out;
        background-size: cover !important;
    }
}

.image-slider img {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
}