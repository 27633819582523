@import "../../assets/styles/content";

#gallery-with-links .gallery {
    margin-top: 50px;
    img {
        width: 100%;
        height: auto;
    }
}

#SRLLightbox {
    // background-color: rgba(255,255,255,1);

    .SRLStage button { background-color: black !important;}
    .SRLStage button div svg path { fill: rgba(255,255,255,1);}
}
.SRLControls button { 
    background-color: black !important;

    div svg path { fill: rgba(255,255,255,1);}
}

.paquete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &:nth-of-type(2) {
        margin-top: 50px;
    }
    .disclaimer { margin-top: 50px; font-weight: 600;}
    .pricing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        box-shadow: 0px 0px 15px #ccc;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .pricing-header {
            padding: 10px;
            width: 100%;
            text-align: center;
            font-weight: 600;
            font-size: 25px;
            font-family: 'Homework';
            // background: black;
            background: #e9e3e3;
            color: black;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:nth-of-type(1) {
            position: relative;
            top: 30px;
            left: 2%;
            z-index: 1;
        }
        &:nth-of-type(2) {
            position: relative;
            border-radius: 5px;
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 33%;
        }
        &:nth-of-type(3) {
            position: relative;
            right: 2%;
            top: 30px;
            z-index: 0;
        }

        img {
            width: 100%;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;        
        }

        &:nth-of-type(2) {
            box-shadow: 0px 0px 10px #000;
        }

        .maternity .pricing .pricing-header { background-color: #e9e3e3;}
    }

    .pricing-details {
        padding: 0px;
        margin: 0px;
        width: 100%;
        // border: 1px solid #cacaca;
        border-top: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        li {
            display: block;
            text-align: center;
            padding: 5px;
            background: #f8f8f8;
            line-height: 35px;
            padding: 15px;
            font-weight: 600;
            font-family: ATypewriterForMe;

            &.price {
                font-size: 30px;
                font-weight: 600;
                font-family: Homework;
                line-height: 60px;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &:nth-child(odd) { /*background: black;*/ background: #e9e3e3; color: black; border-color: black;}
        }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
    .paquete {
        flex-direction: column;

        .pricing { 
            width: 100% !important; 
            
            &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3) { 
                top: 0px; 
                padding: 5px;
                position: static;
                box-shadow: 0px 0px 5px #ccc;
            }
        }
    }
}