@font-face {
    font-family: ATypewriterForMe;
    src: url(fonts/ATypewriterForMe/ATypewriterForMe.ttf);
}

header {
    display: flex;
    z-index: 9999;
    flex-direction: column;
    align-items: center;
    font-family: ATypewriterForMe;
    font-weight: 500;
    position: absolute;
    top: 10em;
    width: 100%;

    .mobile-nav {
        display: none;
        color: white;
    }
}

.site-menu {
    margin: 1.5em 0;
}

.site-menu ul > li{
    display: inline;
    margin: 5px;
}

.site-menu ul > li > a {
    text-decoration: none;
    list-style: none;
    color: white;
    text-transform: uppercase;
}

.site-menu ul > li > a:hover {
    border-bottom: 1px solid white;
}

li.active > a{border-bottom: 1px solid white;}

.site-menu ul {
    text-align: center;
    margin: 0;
    padding: 0;
}

.logo-container {
    height: 90px;
    width: 700px;
    margin: 10px 30px;
    background: url('logos/logo-full-width-white.png') no-repeat;
    background-size: contain;
}

.logo {
    align-items: center;
    height: 85px;
}

.scrolled {
    position: fixed;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    left: 0;
    background: rgba(0,0,0,1);
}

.scrolled .logo-container {
    &.square{
        height: 70px;
        width: 70px;
        margin: 10px 80px;
        background: url('logos/logo-square-white.png') no-repeat;
        background-size: contain;
    }
}

.scrolled .logo-container > .logo {
    height: 80px;
    padding: 20px;
    padding-left: 45px;
}

.scrolled .site-menu li {
    padding: 5px;
    transition: margin 0.5s ease;
    white-space: nowrap;
}



@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
    .scrolled {
        .logo-container {
            &.square {
                height: 35px;
                width: 35px;
                margin: 10px auto;
            }
        }

        .mobile-nav { border-top: 1px #545454 solid; }
    }

    header {
        display: block;
        .logo-container {
            height: 45px;
            width: 250px;
            margin: 0 auto;
        }

        .mobile-nav {
            display: block;
            color: white;
            text-align: center;
            padding: 2px;
            font-size: 15px;
        }
    
        .site-menu {
            width: 100%;
            margin: 0 auto;
            display: none;

            &.show { display: block; }
            ul {
                margin: 1px 0;
    
                > li {
                    display: block;
                    padding: 0px 5px;
                }
            }
        }
    }
}


.courses .col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px;
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 2px 0px 12px -8px;
}